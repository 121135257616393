import React from "react";

export default function WordsSlider({ words = [] }) {
  const array = new Array(3).fill(0);

  return (
    <section
      className="relative h-72 2xl:h-96 w-full overflow-hidden"
      aria-hidden="true"
    >
      <div className="absolute top-1/2 -translate-y-1/2 flex">
        {array.map((value, index) => {
          const key = `${index}-${value}`;
          return (
            <ul key={key} className="flex animate-swipe">
              {words.map((word) => {
                return (
                  <li
                    key={word}
                    className="whitespace-nowrap text-7xl 2xl:text-9xl text-light-dark font-medium font-important tracking-wider before:content-['•'] before:p-4"
                  >
                    {word}
                  </li>
                );
              })}
            </ul>
          );
        })}
      </div>
    </section>
  );
}
