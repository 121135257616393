import React from "react";
import Link from "next/link";
import ArrowUpRightIcon from "components/SVG/Icon/ArrowUpRightIcon";
import classNames from "lib/classNames";

export default function MoreLink({
  children,
  href,
  theme = "default",
  scroll = true,
}) {
  const themes = {
    primary: {
      link: "text-white hover:text-primary",
      decoration: "before:bg-primary",
      icon: "text-primary group-hover:text-white",
    },
    default: {
      link: "text-dark",
      decoration: "before:bg-dark",
      icon: "text-dark",
    },
    active: {
      link: "text-primary",
      decoration: "before:bg-primary",
      icon: "text-white",
    },
  };
  return (
    <Link href={href} prefetch={false} scroll={scroll} passHref legacyBehavior>
      <a
        className={classNames(
          "group inline-flex items-baseline gap-4 font-important tracking-wider font-semibold text-md 2xl:text-lg transition-colors duration-300",
          themes[theme].link
        )}
      >
        <span
          className={classNames(
            "relative block leading-none pb-2 before:content-[''] before:absolute before:bottom-0 before:left-0 before:right-0 before:h-0.5 before:transition-all before:duration-300 group-hover:before:right-1/2",
            themes[theme].decoration
          )}
        >
          {children}
        </span>
        <span className="h-3 w-3 overflow-hidden">
          <ArrowUpRightIcon
            className={classNames(
              "h-3 w-3 group-hover:animate-swipe-diagonal transition-colors duration-300",
              themes[theme].icon
            )}
          />
        </span>
      </a>
    </Link>
  );
}
