import React from "react";
import Image from "next/image";
import Body from "components/Body";

export default function NodeTeamMemberAuthor({ node }) {
  return (
    <address className="flex items-center gap-4 not-italic">
      <div className="flex-initial relative w-[70px] h-[70px] border-2 border-primary rounded-full overflow-hidden scale-radius">
        <Image
          src={node.field_image.image_style_uri.small_square}
          alt={node.field_image.resourceIdObjMeta.alt}
          fill
        />
      </div>
      <div className="flex-1">
        <div className="text-lg font-bold leading-tight">{node.title}</div>
        <div className="font-medium text-lg leading-tight opacity-40">
          <Body value={node.body.processed} />
        </div>
      </div>
    </address>
  );
}
