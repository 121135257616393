import React from "react";
import formatDate from "lib/formatDate";
import NodeTeamMemberAuthor from "components/TeamMember/NodeTeamMemberAuthor";
import { useRouter } from "next/router";
import Link from "next/link";

export default function NodeArticleTeaser({ node }) {
  const date = formatDate(node.created);
  const router = useRouter();

  return (
    <article
      className="cursor-pointer"
      onClick={() => router.push(node.path.alias)}
    >
      <div className="py-8">
        <time dateTime={node.created} className="block font-bold text-lg mb-4">
          {date}
        </time>
        <h3 className="font-important tracking-wider text-2xl 2xl:text-3xl font-semibold mb-4">
          <Link href={node.path.alias}>{node.title}</Link>
        </h3>
        {node.body?.summary && (
          <div className="prose prose-default mb-4">
            <p>{node.body.summary}</p>
          </div>
        )}
        {node.field_author && <NodeTeamMemberAuthor node={node.field_author} />}
      </div>
    </article>
  );
}
