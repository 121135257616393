import React from "react";
import { m } from "framer-motion";
import MoreLink from "components/MoreLink";
import { fadeIn, fadeUp } from "lib/variants";
import Headings from "components/Headings";
import Scribbles1 from "components/SVG/Scribble/Scribbles1";
import NodeArticleList from "components/Article/NodeArticleList";

export default function ArticlesSections({ nodes }) {
  return (
    <m.section variants={fadeIn} className="mb-16 2xl:mb-28">
      <div className="relative container px-4 lg:px-12 mx-auto">
        <Headings
          pretitle="De la tech mais pas que !"
          title="Le Blog"
          scribble={
            <Scribbles1 className="absolute top-1/2 left-0 -translate-y-1/2 w-1/3 md:w-[13vw] text-primary" />
          }
        />

        <NodeArticleList nodes={nodes} />

        <m.div
          variants={fadeUp}
          initial="out"
          whileInView="enter"
          className="lg:text-right mt-12 will-change-transform"
        >
          <MoreLink href="/blog">Toutes nos articles</MoreLink>
        </m.div>
      </div>
    </m.section>
  );
}
