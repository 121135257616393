import React from "react";
import NodeArticleTeaser from "components/Article/NodeArticleTeaser";
import { m } from "framer-motion";
import { fadeUp } from "lib/variants";

export default function NodeArticleList({ nodes }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 2xl:gap-12">
      {nodes.map((node) => {
        return (
          <m.div
            key={node.id}
            variants={fadeUp}
            initial="out"
            whileInView="enter"
            className="will-change-transform"
          >
            <NodeArticleTeaser node={node} />
          </m.div>
        );
      })}
    </div>
  );
}
