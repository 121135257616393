import React from "react";
import { m } from "framer-motion";
import classNames from "lib/classNames";
import { fadeUp } from "lib/variants";

export default function Headings({
  title = null,
  pretitle = null,
  subtitle = null,
  scribble = null,
  marginTop = false,
}) {
  return title || pretitle || subtitle ? (
    <div className={classNames("relative", marginTop && "mt-12 2xl:mt-28")}>
      {pretitle && (
        <m.span
          variants={fadeUp}
          initial="out"
          whileInView="enter"
          className="relative block mb-2.5 text-lg 2xl:text-xl font-bold will-change-transform z-10"
        >
          {pretitle}
        </m.span>
      )}
      {title && (
        <m.div
          variants={fadeUp}
          initial="out"
          whileInView="enter"
          className="relative will-change-transform mb-10"
        >
          {scribble}
          <h2 className="relative text-4xl sm:text-5xl 2xl:text-6xl break-words sm:break-normal font-bold">
            {title}
          </h2>
        </m.div>
      )}
      {subtitle && (
        <m.h3
          variants={fadeUp}
          initial="out"
          whileInView="enter"
          className="relative text-3xl 2xl:text-4xl font-bold will-change-transform mb-6"
        >
          {subtitle}
        </m.h3>
      )}
    </div>
  ) : null;
}
